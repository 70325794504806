import { Error } from 'components/Errors/Error';
import { useState } from 'react';
import { SignatoriesTab } from './SignatoriesTab';
import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultValuesInvolvedForm,
    validationSchemaInvolvedForm,
} from 'components/Tabs/Involved/InvolvedSchema';
import { useEnumContext } from 'contexts/enumContext';
import { usePersonsList } from 'contexts/personContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { SignatoriesForm } from './SignatoriesForm';
import { showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { SignatoriesHeader } from './SignatoriesHeader';
import { Grid } from '@mui/material';
import DrawerAddPerson from 'components/DrawerAddPerson/DrawerAddPerson';

export const SignatoriesTabContainer = () => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const { status, data: typeOfRelationship, error } = useEnumContext({ enumName: "TypeOfRelationship", size: 50 });
    const { status: signatureStts, data: signatureType, error: signatureErr } = useEnumContext({ enumName: "SignatureType" });
    const { status: SignatureValidationStts, data: signatureValidation, error: SignatureValidationErr } = useEnumContext({ enumName: "SignatureValidation", size: 50 });
    const [isAddPerson, setIsAddPerson] = useState<boolean>(false);

    const [toast, setToast] = useState<ToastType>(toastState);
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const [selectedInvolvedIndex, setSelectedInvolvedIndex] = useState<number | undefined>();
    const { setValue, watch } = useFormContext();
    const signatories = watch('relatedPerson');

    if ((status || SignatureValidationStts || signatureStts) === "loading") return <RefreshProgress refreshing={true} />;
    if ((status || signatureStts || SignatureValidationStts) === "error") return <Error error={error || signatureErr || SignatureValidationErr} />;

    const setNewRelatedPerson = (values: any) => {
        let payload = { ...values };

        let newRelatedPerson = signatories !== undefined ? [...signatories] : [];
        if (selectedInvolvedIndex === undefined) newRelatedPerson?.push(payload);
        else newRelatedPerson[selectedInvolvedIndex!] = payload;

        setValue('relatedPerson', newRelatedPerson);
        const title = 'Lista de envolvidos atualizada com sucesso!';
        const description = 'Ótimo! agora você pode adicionar um novo envolvido.'
        showSuccessToast(title, description, setToast);
        closeDrawer();
    };

    const closeDrawer = () => {
        setSelectedInvolvedIndex(undefined);
        setOpenDrawer(false);
    };

    function onDelete(index: number) {
        let rowData = [...signatories];
        rowData?.splice(index, 1);
        if (watch('relatedPerson')) setValue('relatedPerson', rowData);
        else setValue('relatedPerson', rowData);
        showSuccessToast('Sucesso ao excluir o envolvido!', undefined, setToast);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const involvedSchema = validationSchemaInvolvedForm('relatedPerson');

    const defaultFormValue =
        selectedInvolvedIndex !== undefined
            ? signatories[selectedInvolvedIndex!]
            : defaultValuesInvolvedForm;

    return (
        <>
            <Grid m="20px 0">
                <SignatoriesHeader handleClick={() => setOpenDrawer(true)} />
            </Grid>
            <Toast toast={toast} setToast={setToast} />

            <SignatoriesTab
                name={'relatedPerson'}
                onDelete={onDelete}
                setSelectedInvolvedIndex={(rowNumber: number | undefined) =>
                    setSelectedInvolvedIndex(rowNumber ?? signatories?.length)
                }
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
            />

            <DrawerAddPerson
                open={isAddPerson}
                onClose={() => setIsAddPerson(false)}
                isAddPerson={isAddPerson}
                toggleDrawer
            />

            <Drawer
                anchor="right"
                title="Adicionar signatário"
                description="Confira as informações antes de adicionar o envolvido"
                open={selectedInvolvedIndex !== undefined || openDrawer}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={involvedSchema}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewRelatedPerson}
                >
                    <SignatoriesForm
                        typeOfRelationship={typeOfRelationship ?? []}
                        signatureType={signatureType ?? []}
                        signatureValidation={signatureValidation ?? []}
                        onClose={closeDrawer}
                        personList={personAutoCompleteProps}
                        setIsAddPerson={setIsAddPerson}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};
