import { GetListApiResponseSuccess, GetViewApiResponse } from 'contexts/apiRequestContext';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import {
    LiquidationScheduleCreditNoteFilters,
    LiquidationScheduleRequestModel,
    mapLiquidationScheduleData,
} from './types';
import { LiquidationScheduleReadModel } from './types/LiquidationScheduleReadModel';
import { ApprovalLiquidationScheduleCreateModel, mapApprovalScheduleData } from './types/ApprovalLiquidationScheduleRequest';
import { mapPaymentReviewItems } from './types/LiquidationScheduleRequestModel';

function createAxiosConfig(token: string, params?: Record<string, unknown>) {
    return {
        headers: { Authorization: `Bearer ${token}` },
        params,
    };
}

const url = '/CreditNote';
export async function editOrCreateLiquidationSchedule(
    data: LiquidationScheduleRequestModel,
    id: string,
    token: string = '',
    liquidationId?: string
) {
    const baseEndpoint = `${url}/${id}/LiquidationSchedule`;
    const endpoint = liquidationId ? `${baseEndpoint}/${liquidationId}` : baseEndpoint;

    const axiosConfig = createAxiosConfig(token);
    const payload = createPayload(data);

    const axiosFunction = liquidationId ? creditAxiosApi.put : creditAxiosApi.post;
    return await axiosFunction<GetViewApiResponse<LiquidationScheduleReadModel>>(
        endpoint,
        payload,
        axiosConfig
    );
}

function createPayload(data: LiquidationScheduleRequestModel) {
    return mapLiquidationScheduleData<LiquidationScheduleRequestModel>(data);
}

export async function getLiquidationScheduleCreditNote(
    filters: LiquidationScheduleCreditNoteFilters,
    creditNoteId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/LiquidationScheduleCreditNote`;
    const axiosConfig = createAxiosConfig(token, filters);

    return await creditAxiosApi.get<GetListApiResponseSuccess<LiquidationScheduleReadModel>>(
        endpoint,
        axiosConfig
    );
}

export async function approveLiquidationSchedule(
    data: ApprovalLiquidationScheduleCreateModel,
    creditNoteId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/approveLiquidationSchedule`;
    const payload = mapApprovalScheduleData(data)
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(endpoint, payload, axiosConfig);
}

export async function getPixkeyAsync(pixKey: string, token: string) {
    const endpoint = `/BankAccount/Pix/Key/${pixKey}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.get(endpoint, axiosConfig);
}

export function getBankslipAsync(barCode: string, token: string) {
    const endpoint = `/BankAccount/Pay/Bankslip`;
    const axiosConfig = createAxiosConfig(token, { barCode });

    return creditAxiosApi.get(endpoint, axiosConfig);
}

export function getPixQrCodeAsync(qrCode: string, token: string) {
    const endpoint = `/BankAccount/Pix/QRCode`;
    const axiosConfig = createAxiosConfig(token, { qrCode });

    return creditAxiosApi.get(endpoint, axiosConfig);
}

export function postApproveRevisionAsync(payload: LiquidationScheduleReadModel[], creditNoteId: string, token: string) {
    const endpoint = `${url}/${creditNoteId}/paymentRevisionLiquidationSchedule`;
    const axiosConfig = createAxiosConfig(token);
    const paymentReview = mapPaymentReviewItems(payload);

    return creditAxiosApi.post(endpoint, paymentReview, axiosConfig);
}

export function deleteLiquidationScheduleAsync(creditNoteId: string, liquidationId: string, token: string) {
    const endpoint = `${url}/${creditNoteId}/LiquidationSchedule/${liquidationId}`;
    const axiosConfig = createAxiosConfig(token);

    return creditAxiosApi.delete(endpoint, axiosConfig);
}