import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiResponseError,
  IBase,
  ToastType,
  showErrorToast,
  showSuccessToast,
  showWarningToast,
  toastState,
  useApiRequest,
} from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import {
  LegalPersonFormSchema,
  defaultValuesLegalPersonForm,
  validationSchemaLegalPersonForm,
} from './LegalPersonSchema';
import LegalPersonForm from './LegalPersonForm';
import { useLegalPersonData, useLegalPersonMutation } from 'contexts/legalPersonContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import React, { useState } from 'react';
import AssignDrawer from 'components/Assign/AssignDrawer';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { Drawer, Modal } from '@uy3/web-components';
import { DeleteCheckListContainer } from 'pages/CreditNote/CreditNoteForm/Modals/DeleteCheckList/DeleteCheckListContainer';
import { useComplianceChecklistVerifyPersonId } from 'contexts/creditNote/complianceCheckListContext';
import Toast from 'components/Toast/Toast';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { FieldValues } from 'react-hook-form';
import { createLegalPerson } from 'services/accounts/legalPerson';
import { useIdentity } from 'contexts/identityContext';
import { redirectCloneRegister } from 'helpers/methods/RedirectCloneRegister';
import { CloneRegisterContainer } from 'components/CloneRegister/CloneRegisterContainer';
import FraudRecordContainer, {
  FraudRecord,
} from 'components/GenericForms/FraudRecord/FraudRecordContainer';
import FraudRecordAlert from 'components/GenericForms/FraudRecord/Alert';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { useFraudRecordMutation } from 'contexts/fraudRecordContext';
import { QueryBasicDataContainer } from 'components/QueryBasicData/QueryBasicDataContainer';
import { BlackListContainer } from 'components/BlackList/BlackListContainer';

export const LegalPersonFormContainer = () => {
  const { personId } = useParams();
  const { token } = useIdentity();
  const formMode: FormMode = personId !== 'novo' ? 'update' : 'create';
  const { legalPersonData, legalPersonError, legalPersonStatus, refetch, isLoading } =
    useLegalPersonData(personId!);
  const { submitError, submitting, setSubmitError } = useApiRequest();
  const { setFormFieldsErrors } = useFormFieldsError();
  const { hasPermission } = useUserPermissionData();
  const typePerm = formMode === 'create' ? 'Create' : 'Update';
  const hasPermissionCreateOrUpdate: boolean = hasPermission('LegalPerson', typePerm);
  const navigate = useNavigate();
  const { data: complianceChecklist, refetchBatchAssignment } = useComplianceChecklistVerifyPersonId(personId!);

  const [openDrawer, setOpenDrawer] = useState<{ open: boolean; type: string } | undefined>(
    undefined
  );
  const onClose = () => setOpenDrawer(undefined);
  const [toast, setToast] = useState<ToastType>(toastState);

  const onSuccess = (data: any) => {
    if (formMode === 'create') {
      const id = formMode === 'create' ? data : data.id ?? personId;
      navigate(`/pessoas-juridicas/todas/${id}`);
    }
    if (personId !== 'novo') {
      refetch();
    }
    setSubmitError(undefined);
    setFormFieldsErrors([]);
    const title =
      formMode === 'create'
        ? 'Pessoa jurídica criada com sucesso!'
        : 'Pessoa jurídica atualizada com sucesso!';
    const description = undefined;
    showSuccessToast(title, description, setToast);
  };
  const onError = (error: ApiResponseError) => {
    const { errorMessage, fieldErrors, warningToastError } = mapErrorResponse(error);
    setFormFieldsErrors(fieldErrors ?? []);
    setSubmitError(error);
    if (warningToastError && warningToastError?.length > 0) {
      const title = 'Atenção!';
      showWarningToast(title, warningToastError, setToast);
    } else {
      const title = 'Ops, ocorreu um erro!';
      const description = errorMessage;
      showErrorToast(title, description, setToast);
    }
  };

  const cloneCurrentLegalPerson = async (values: FieldValues) => {
    await createLegalPerson(legalPersonData, values?.tenant, token!)
      .then((response: any) => {
        return redirectCloneRegister({
          redirect: () => navigate(`/pessoas-juridicas/todas/${response?.data!}`),
          onClose,
          setToast,
        });
      })
      .catch((error: ApiResponseError) => onError(error));
  };

  const { mutate } = useLegalPersonMutation(personId!, onSuccess, onError);
  const onSubmit = async (values: LegalPersonFormSchema) => {
    mutate(values);
  };
  const schema = validationSchemaLegalPersonForm();
  const defaultValues = legalPersonData ?? defaultValuesLegalPersonForm;

  const onSuccessFraud = () => {
    const title = 'Fraude registrada com sucesso!';
    const desc = 'Ótimo! Agora você pode visualizar a fraude registrada.';
    showSuccessToast(title, desc, setToast);
    onClose();
    refetch();
  };

  const { mutateAsync, status } = useFraudRecordMutation(personId!, onSuccessFraud, onError);
  const handleOnFraud = (values: FieldValues) => mutateAsync(values);

  const fraudRecordDetails: FraudRecord = {
    name: legalPersonData?.companyName,
    registrationNumber: legalPersonData?.registrationNumber,
    taxPayer: 'PJ',
    tradeName: legalPersonData?.name,
  };

  return (
    <GenericErrorBoundary
      fallback="menu pessoa jurídica"
      status={legalPersonStatus}
      error={legalPersonError}
    >
      <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
        <React.Fragment>
          <Toast toast={toast} setToast={setToast} />
          <ApiErrorAlert error={submitError} />
          {status === 'success' && <FraudRecordAlert />}
          <RefreshProgress refreshing={submitting} />
          <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            readOnly={!hasPermissionCreateOrUpdate}
          >
            <React.Fragment>
              <LegalPersonForm
                hasPermissionCreateOrUpdate={hasPermissionCreateOrUpdate}
                mode={formMode}
                personId={personId!}
                complianceChecklist={complianceChecklist}
                setOpenDrawer={setOpenDrawer}
                refetch={refetch}
                setToast={setToast}
              />

              <QueryBasicDataContainer
                enableModal={!!openDrawer && openDrawer.type === 'getPersonByRegistrationNumber'}
                onClose={onClose}
                resource="LegalPerson"
              />
            </React.Fragment>
          </FormProvider>

          <AssignDrawer
            recordId={personId!}
            recordType="LegalPerson"
            openDrawer={!!openDrawer && openDrawer?.type === 'assign'}
            onClose={onClose}
            navigate="/pessoas-juridicas/todas"
          />

          <Drawer
            anchor="right"
            open={!!openDrawer && openDrawer.type === 'fraudRecord'}
            title="Registrar Fraude"
            description="Antes de registrar uma fraude, informe o motivo."
            onClose={onClose}
            children={
              <FraudRecordContainer
                {...{
                  onClose,
                  onFraudRecordSubmit: handleOnFraud,
                  fraudRecordDetails,
                }}
              />
            }
          />

          <Modal
            open={!!openDrawer && openDrawer.type === 'addBlackList'}
            onClose={onClose}
            align="left"
            title="Incluir registro na Blacklist"
            description="Preencha as informações abaixo para inluir esse registro."
            children={
              <BlackListContainer
                onClose={onClose}
                resource='LegalPerson'
                data={legalPersonData}
                setToast={setToast}
                refetchBatchAssignment={refetchBatchAssignment}
              />
            }
          />

          <Modal
            open={!!openDrawer && openDrawer.type === 'deletecomplianceChecklist'}
            onClose={onClose}
            align="center"
            title="Remover restrições"
            description="Escolha quais restrições deseja remover desse registro"
            children={
              <DeleteCheckListContainer onClose={onClose} personId={personId!} />
            }
          />
          <Modal
            open={!!openDrawer && openDrawer.type === 'clone'}
            align="left"
            title="Clonar pessoa jurídica"
            description="Realize uma cópia do registro atual informando o correspondente."
            onClose={onClose}
            children={
              <CloneRegisterContainer
                data={legalPersonData as IBase}
                onClose={onClose}
                resource="naturalAndLegalPerson"
                onSubmit={cloneCurrentLegalPerson}
              />
            }
          />
        </React.Fragment>
      </GenericFormSkeleton>
    </GenericErrorBoundary>
  );
};
