import { GetViewApiResponse, UploadGet } from 'contexts/apiRequestContext';
import { CreditNoteReadModel, CreditNoteProps, DonePaymentRevision, GeneratePaymentLinkProps, TransferReceiptPdfType } from './types/creditNoteReadModel';
import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { FieldValues } from 'react-hook-form';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { onlyNumbers } from 'helpers/validations/formFieldValidator';
import { CreditNoteOpCreateModel } from './types/creditNoteCreateModel';
import { CreditNoteOpUpdateModel } from './types/creditNoteUpdateModel';
import { AmortizationTypes, toAmortizationDataModel } from 'services/Amortization';
import { CheckAverbationResponseType, CosifReadModel } from './types/genericTypes';
import { LiquidationScheduleRequestModel, mapLiquidationScheduleData } from 'services/LiquidationSchedule';

var url = '/CreditNote';

export const getCreditNoteList = async (filters: CreditNoteProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };
    return await creditAxiosApi.get(url, config);
};

export async function editOrCreateCreditNote(
    data: CreditNoteReadModel,
    id: string,
    token = '',
    tenant?: string
) {
    const isCreating = id === 'nova';
    const urlFull = isCreating ? url : `${url}/${id}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const CreditNoteCreateOrUpdate = isCreating ? CreditNoteOpCreateModel : CreditNoteOpUpdateModel;
    const creditNoteCreateOrUpdate = toDataModel(data as Record<keyof CreditNoteReadModel, unknown>, CreditNoteCreateOrUpdate);

    if (creditNoteCreateOrUpdate?.amortization) {
        creditNoteCreateOrUpdate.amortization = toAmortizationDataModel<AmortizationTypes>(creditNoteCreateOrUpdate.amortization);
    }

    if (!!creditNoteCreateOrUpdate?.liquidationSchedule?.length) {
        creditNoteCreateOrUpdate.liquidationSchedule =
            creditNoteCreateOrUpdate.liquidationSchedule.map((i) => mapLiquidationScheduleData<LiquidationScheduleRequestModel>(i));
    }

    if (creditNoteCreateOrUpdate?.warranty && Array.isArray(creditNoteCreateOrUpdate?.warranty)) {
        creditNoteCreateOrUpdate.warranty = creditNoteCreateOrUpdate.warranty.map(item => {
            if (item.payerRegistrationNumber) {
                item.payerRegistrationNumber = onlyNumbers(item.payerRegistrationNumber);
            }
            return item;
        });
    }

    const payload = {
        ...creditNoteCreateOrUpdate,
        paymentScheduleItems:
            creditNoteCreateOrUpdate?.paymentScheduleItems?.length > 0
                ? creditNoteCreateOrUpdate.paymentScheduleItems
                : null,
    };

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<GetViewApiResponse<CreditNoteReadModel>>(urlFull, payload, axiosConfig);
}

export async function putCreateCreditNoteById(creditNoteId: string, paylod: CreditNoteReadModel, token: string, tenant?: string) {
    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };
    return await creditAxiosApi.put(`/CreditNote/${creditNoteId}`, paylod, axiosConfig);
}


export async function getCreditNoteById(id: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<CreditNoteReadModel>(url + `/${id}`, config);
}

export async function deleteCreditNoteById(id: string, token?: string, tenant?: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    return await creditAxiosApi.delete(url + `/${id}`, config);
}

export async function submitApprovalCreditNoteById(id: string, token?: string, updateStartDate?: boolean) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { updateStartDate }
    };

    return await creditAxiosApi.post(url + `/${id}/submitapproval`, {}, config);
}

export function duplicateCreditNote(data: any, token?: string, tenant?: string) {
    const filter = ['status', 'paymentScheduleItems', 'timeline', 'creditNoteNo', 'id', 'uploads'];
    filter.forEach((key) => delete data[key]);

    return editOrCreateCreditNote(data, "nova", token, tenant);
}

export type DisapproveDataProps = {
    attributeTypes: string[];
    message: string;
    insertBlock: string;
    blockUntil: string;
    rejectionReasonCode: string[];
};

export async function disapproveCreditNoteById(
    data: DisapproveDataProps,
    id: string,
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${id}/disapprove`, data, config);
}

export type SignatureCreditNoteProps = {
    id: string;
    token: string;
    message?: string;
    reject?: boolean;
    type: "signature" | "approveSignature"
};

export async function signatureCreditNoteById(props: SignatureCreditNoteProps) {
    const { id, token, message, reject = false, type = 'signature' } = props;
    const endpoint = type !== 'signature' ? 'approveSignature' : 'signature';
    var urlParams = reject ? `/${id}/${endpoint}?reprove=true` : `/${id}/${endpoint}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + urlParams, { message }, config);
}

export async function sendSignatoryCreditNoteById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${id}/sendNotification`, {}, config);
}

export async function sendAssignmenteEmailCreditNoteById(id: string, token: string, email: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${id}/sendassignmentemail?sendOnlyTo=${email}`, {}, config);
}

export async function updateFiles(files: UploadGet, id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.put(url + `/${id}/upload`, { uploads: files }, config);
}

export async function paymentRevisionCreditNoteById(id: string, payload: DonePaymentRevision, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${id}/donePaymentRevision`, payload, config);
}

export async function installmentCreditNoteById(id: string, payload: object, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${id}/installment`, payload, config);
}

export async function checkCreditCardOperationsById(id: string, token: string) {
    var url = `/CreditCardOperations/${id}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url, {}, config);
}

export async function cancelCreditNoteById(data: any, id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${id}/cancel`, data, config);
}

export async function manualUpdateCreditNote(
    data: any,
    id: string,
    token: string,
    tenant: string | undefined
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    return await creditAxiosApi.post(url + `/${id}/manualUpdate`, data, config);
}

export async function approveCreditNoteById(
    id: string,
    token: string,
    status: string,
    payload?: any
) {

    if (status === "ManualLiquidation") {
        status = 'Liquidation';
    }

    var urlFull = url + `/${id}/approve${status.replace('Approval', '')}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(urlFull, payload, config);
}

export async function assignmentPreviewCreditNoteById(
    id: string,
    token: string,
    useWatermark: boolean,
    createPdf: boolean
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { useWatermark, createPdf },
    };

    return await creditAxiosApi.post(url + `/${id}/assignmentpreview`, {}, config);
}

export async function draftPreviewCreditNoteById(
    id: string,
    token: string,
    useWatermark: boolean,
    createPdf: boolean
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { useWatermark, createPdf },
    };

    return await creditAxiosApi.post(url + `/${id}/draftpreview`, {}, config);
}

export async function getRecoverSimulationCreditNote(simulationId: string, token: string) {
    var url = `/Amortization/${simulationId}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get(url, config);
}

export async function sendWebCredCreditNote(id: string, token: string, tenant: string | undefined) {
    const url = `/WebCred/${id}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    return await creditAxiosApi.post(url, {}, config);
}

export async function restoreCreditFromDeleted(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(`${url}/${id}/restore`, {}, config);
}

export async function approveliquidCreditNoteById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(`${url}/${id}/approveLiquidation`, {}, config);
}

export async function generateBatchAssignment(data: any, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return creditAxiosApi.post(`/BatchAssignment`, data, config);
}

export async function generatePaymentLink(data: GeneratePaymentLinkProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`/CreditCardOperations/generatePaymentLink`, data, config);
}

export async function getCosifAccountTransaction(token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.get<Array<CosifReadModel>>(`/CosifAccount?transactionType=Transfer`, config);
}

export async function postRemoveBlock(creditNoteId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${creditNoteId}/RemoveBlock`, {}, config);
}

export async function postTransferReceiptPdf(creditNoteId: string, params: TransferReceiptPdfType, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };
    return await creditAxiosApi.post(url + `/${creditNoteId}/TransferReceiptPdf`, {}, config);
}

export const getSignaturesCreditNote = async (creditNoteId: string, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.get(`${url}/${creditNoteId}/SignUrl`, config);
}

export const putFinishedManualWarranty = async (creditNoteId: string, payload: FieldValues | null, token: string) => {
    let config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/${creditNoteId}/approveWarranty`, payload, config);
}

export const postApproveMarginReserve = async (creditNoteId: string, token: string, payload?: FieldValues) => {
    let config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(`${url}/${creditNoteId}/approveMarginReserve`, payload, config);
}

export const postDisapproveMarginReserve = async (creditNoteId: string, payload: FieldValues, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/${creditNoteId}/disapproveMarginReserve`, payload, config);
}

export const postReprocessByStatus = async (creditNoteSequence: string, payload: string[], token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/ReprocessByStatus/${creditNoteSequence}`, payload, config);
}

export async function checkAverbationCreditNoteFGTS(creditNoteId: string, token: string) {

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            restartWf: false,
            ignoreRuleDays: false
        }
    };
    return await creditAxiosApi.post<CheckAverbationResponseType>(`/CreditNote/${creditNoteId}/CheckAverbacao`, {}, axiosConfig);
}

